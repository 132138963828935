import React from "react";
import styles from "./Preview.module.css";
import img from "./prev/xxx.jpg";
import x from "./prev/День_рождения.png";
import xx from "./prev/Корпоратив.png";

import xxxx from "./prev/Свадьба.png";

function Preview() {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <img src={img} alt="error" className={styles.img} />
          <div className={styles.blockInfo}>
            <h2>Банкетный зал "ДЫМ ШАШЛЫК"</h2>
            <ul className={styles.list}>
              <li className={styles.listItem}>
                <img src={xxxx} alt="error" className={styles.imgList} />
                <span className={styles.text}>свадьбы</span>
              </li>
              <li className={styles.listItem}>
                <img src={x} alt="error" className={styles.imgList} />
                <span className={styles.text}>дни рождения</span>
              </li>
              <li className={styles.listItem}>
                <img src={xx} alt="error" className={styles.imgList} />{" "}
                <span className={styles.text}>корпоративы</span>
              </li>
            </ul>
            <div className={styles.blockText}>
              <p>
                Мы предлагаем разнообразное меню на любой вкус и бюджет — вам
                остаётся только наслаждаться праздником! Забронируйте зал и
                создайте незабываемое событие для себя и ваших гостей.
              </p>
            </div>
          </div>
          <div className={styles.buttonBlock}>
            <button className={styles.button}>
              <a href="tel:+79619797977">Позвонить</a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Preview;
