import React, { useEffect, useState } from "react";
import styles from "./Content.module.css";
import Navigation from "./Navigation/Navigation";
import ItemCard from "./Item/ItemCard";
import Skeleton from "../Skeleton/Skeleton";
import DeliveryModal from "../Modal/DeliveryModal/DeliveryModal";
import { useSelector } from "react-redux";

function Content(props) {
  const [text, setText] = useState("Доставка");
  const delivery = useSelector((state) => state.basket.delivery);

  const textDelivery = delivery === "Доставка" ? "" : "Самовывоз:";

  useEffect(() => {
    if (delivery !== null) {
      setText(delivery);
    }
  }, [delivery]);

  const itemCard = props.isLoading
    ? [...new Array(6)].map((_, index) => <Skeleton key={index} />)
    : props.item.map((meal) => (
        <ItemCard
          key={meal.id}
          id={meal.id}
          name={meal.name}
          img={meal.img}
          description={meal.description}
          price={meal.price}
          weight={meal.weight}
          categoryName={meal.categoryName}
        />
      ));

  return (
    <main className={styles.content}>
      <Navigation navigation={props.navigation} />
      <div className={styles.titleDelivery}>
        <div>
          <p>{textDelivery + " " + text}</p>
        </div>
        <div className={styles.edit}>
          <DeliveryModal props="Изменить" />
        </div>
      </div>
      <section className={styles.container}>{itemCard}</section>
    </main>
  );
}

export default Content;
